<template>
  <div class="staff_unavail">
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-alert
          class="col-span-2"
          v-if="error"
          variant="error"
          :error-message="error"
        >
        </asom-alert>
        <asom-form-field
          label="Officer"
          required
          :state="inputStates('formData.officer')"
          error="Select an officer"
        >
          <asom-input-select
            :state="inputStates('formData.officer')"
            v-model="formData.officer"
            :loading="isLoading"
            :options="officerOptions"
            :disabled="true"
            @search-change="queryOfficerList"
            placeholder="Type to search for officer name"
          />
        </asom-form-field>
        <asom-form-field
          disabled
          label="Plan Date"
          required
          :state="inputStates('formData.planDate')"
          error="Please provide plan date"
        >
          <asom-input-date
            :disabled="true"
            v-model="formData.planDate"
            :state="inputStates('formData.planDate')"
          />
        </asom-form-field>
        <asom-form-field
          label=""
          :state="inputStates('formData.notAvailableForWholeShift')"
        >
          <asom-input-checkbox
            :value="''"
            :checked="formData.notAvailableForWholeShift"
            @change="
              () =>
                (formData.notAvailableForWholeShift =
                  !formData.notAvailableForWholeShift)
            "
            label="Unavailable for Whole Shift"
          />
        </asom-form-field>
        <div
          class="grid sm:grid-cols-1 md:grid-cols-2 gap-5"
          v-if="!formData.notAvailableForWholeShift && shiftStartPickerMode"
        >
          <asom-form-field
            label="Unavailable from"
            required
            :state="inputStates('formData.fromDateTime')"
            error="Please provide start date and time"
          >
            <div class="flex">
              <div class="flex-1">
                <asom-input-date-time
                  v-model="formData.fromDateTime"
                  :state="inputStates('formData.fromDateTime')"
                  :pickerMode="shiftStartPickerMode"
                />
              </div>
              <div class="flex-1 self-center pl-1">
                <span class="italic">(hh:mm)</span>
              </div>
            </div>
          </asom-form-field>
          <asom-form-field
            label="Unavailable to"
            required
            :state="inputStates('formData.toDateTime')"
            error="Please provide end date and time"
          >
            <div class="flex">
              <div class="flex-1">
                <asom-input-date-time
                  v-model="formData.toDateTime"
                  :state="inputStates('formData.toDateTime')"
                  :pickerMode="shiftEndPickerMode"
                />
              </div>
              <div class="flex-1 self-center pl-1">
                <span class="italic">(hh:mm)</span>
              </div>
            </div>
          </asom-form-field>
        </div>
        <div></div>
        <div
          class="flex sm:flex-col md:flex-row items-center justify-start gap-2"
          v-if="!formData.notAvailableForWholeShift && shiftEndPickerMode"
        >
          <asom-button
            :outline="!unavailableMode || unavailableMode != 'first-half'"
            @click="setFirstHalfUnavailable"
            text="Set first-half unavailable"
          />
          <asom-button
            :outline="!unavailableMode || unavailableMode != 'second-half'"
            @click="setSecondHalfUnavailable"
            text="Set second-half unavailable"
          />
        </div>
        <asom-form-field
          required
          label="Remarks"
          :state="inputStates('formData.remarks')"
        >
          <asom-input-textarea
            type="text"
            v-model="formData.remarks"
            :state="inputStates('formData.remarks')"
            :maxlength="1000"
          />
        </asom-form-field>
      </form>
      <template #footer>
        <asom-button
          text="Cancel"
          variant="secondary"
          @click="$router.go(-1)"
        />
        <asom-button
          text="Mark Unavailable"
          @click="onSubmit"
          :loading="isSubmitting"
        />
      </template>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { required, requiredIf } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { listUsers } from "../../services/user.service";
import {
  getRosterDetails,
  markAvailability,
} from "../../services/manpower.service";
import { parseDateTime } from "@/helpers/dateTimeHelpers";

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      formData: {
        officer: null,
        planDate: null,
        fromDateTime: null,
        toDateTime: null,
        notAvailableForWholeShift: false,
        remarks: null,
      },
      originalPlanStart: null,
      originalPlanEnd: null,
      officerOptions: [],
      isLoading: false,
      isSubmitting: false,
      error: null,
      shiftStartPickerMode: null,
      shiftEndPickerMode: null,
      unavailableMode: null,
      timeInterval: null
    };
  },
  validations() {
    return {
      formData: {
        officer: { required },
        planDate: { required },
        fromDateTime: {
          required: requiredIf(() => {
            return this.formData.notAvailableForWholeShift;
          }),
        },
        toDateTime: {
          required: requiredIf(() => {
            return this.formData.notAvailableForWholeShift;
          }),
        },
        remarks: { required },
      },
    };
  },
  mounted() {
    this.v$ && this.v$.$reset();
    this.loadData();
  },
  computed: {
    isValidDateRange() {
      const fromMoment = moment(this.formData.fromDateTime);
      const toMoment = moment(this.formData.toDateTime);
      const origFromMoment = moment(this.originalPlanStart);
      const origToMoment = moment(this.originalPlanEnd);
      return (
        this.formData.notAvailableForWholeShift ||
        (fromMoment.isValid() &&
          origFromMoment.isValid() &&
          fromMoment.isSame(origFromMoment, "minute")) ||
        (toMoment.isValid() &&
          origToMoment.isValid() &&
          toMoment.isSame(origToMoment, "minute"))
      );
    },
  },
  methods: {
    getAvailableDateRange() {
      let fromDateTime = null;
      let toDateTime = null;

      const fromMoment = moment(this.formData.fromDateTime);
      const origFromMoment = moment(this.originalPlanStart);
      const isFromMomentEdited = !fromMoment.isSame(origFromMoment, "minute");

      if(this.unavailableMode){
        if(this.unavailableMode == "first-half") {
          fromDateTime = this.timeInterval.endTimg.from;
          toDateTime = this.timeInterval.endTimg.to;
        }else {
          fromDateTime = this.timeInterval.startTimg.from;
          toDateTime = this.timeInterval.startTimg.to;
        }
      }else {
        if (isFromMomentEdited) {
          fromDateTime = this.originalPlanStart;
          toDateTime = this.formData.fromDateTime;
        } else {
          fromDateTime = this.formData.toDateTime;
          toDateTime = this.originalPlanEnd;
        }
      }

      return {
        fromDateTime: fromDateTime,
        toDateTime: toDateTime,
      };
    },
    // 接口数据回显
    setRosterDetails({ officerId, officer, planDate, planStart, planEnd, hours }) {
      if (officerId && officer) {
        this.formData.officer = {
          label: officer,
          value: officerId,
        };
      }
      const form = this.formData;
      form.planDate = parseDateTime(planDate);
      form.fromDateTime = parseDateTime(planStart);
      form.toDateTime = parseDateTime(planEnd);
      this.originalPlanStart = parseDateTime(planStart);
      this.originalPlanEnd = parseDateTime(planEnd);
      this.timeInterval = this.calculateSegments(form.fromDateTime, form.toDateTime, hours);
      this.setTimePickerModes(
        form.planDate,
        form.fromDateTime,
        form.toDateTime
      );
    },
    setFirstHalfUnavailable() {
      if(this.timeInterval){
        this.formData.fromDateTime = this.timeInterval.startTimg.from;
        this.formData.toDateTime = this.timeInterval.startTimg.to;
      }else {
        this.formData.fromDateTime = this.originalPlanStart;
        const start = moment(this.originalPlanStart);
        const end = moment(this.originalPlanEnd);
        let mid = moment(this.originalPlanStart);
        mid.add(end.diff(start) / 2);
        this.formData.toDateTime = mid.toDate();
      }
      this.unavailableMode = "first-half";
    },
    setSecondHalfUnavailable() {
      if(this.timeInterval){
        this.formData.fromDateTime = this.timeInterval.endTimg.from;
        this.formData.toDateTime = this.timeInterval.endTimg.to;
      }else {
        this.formData.toDateTime = this.originalPlanEnd;
        const start = moment(this.originalPlanStart);
        const end = moment(this.originalPlanEnd);
        let mid = moment(this.originalPlanStart);
        mid.add(end.diff(start) / 2);
        this.formData.fromDateTime = mid.toDate();
      }
      this.unavailableMode = "second-half";
    },
    async loadData() {
      if (this.$route.params.rosterId) {
        const result = await getRosterDetails(this.$route.params.rosterId);
        if (result.success)
          this.setRosterDetails(get(result, "payload.data", {}));
      }
    },
    async onSubmit() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.error = "Please complete all required fields.";
        this.$scrollTop();
      } else if (!this.isValidDateRange) {
        this.error = "Start date or end date must overlap with original shift.";
        this.$scrollTop();
      } else {
        this.error = "";
        this.isSubmitting = true;
        const { fromDateTime, toDateTime } = this.getAvailableDateRange();
        const params = {
          officerId: this.formData.officer.value,
          planDate: parseDateTime(this.formData.planDate),
          notAvailableForWholeShift: this.formData.notAvailableForWholeShift,
          fromDateTime: parseDateTime(fromDateTime),
          toDateTime: parseDateTime(toDateTime),
          remarks: this.formData.remarks,
        }
        const result = await markAvailability(params);
        this.isSubmitting = false;
        if (result.success) {
          const requiredReplacement = get(
            result,
            "payload.requiredReplacement",
            false
          );
          if (requiredReplacement) {
            this.$router.push({
              name: "Staff Replacement",
              params: { rosterId: result.payload.newRosterId },
            });
          } else {
            this.$router.push({ name: "Supervisor Roster" });
          }
        } else {
          this.error = result.payload;
          this.$scrollTop();
        }
      }
    },
    async queryOfficerList(query) {
      this.isLoading = true;
      if (query === null) {
        query = get(this, "$route.params.officer", "");
      }
      if (!query) {
        this.isLoading = false;
        this.officerOptions = [];
      }
      const result = await listUsers({ search: query.trim() });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.userList = get(result, "payload.list");
        this.isLoading = false;
        this.error = null;
        this.officerOptions = get(result, "payload.list").map(
          ({ id, name }) => ({
            label: name,
            value: id,
          })
        );
      } else {
        this.isLoading = false;
        this.error = result.payload;
        this.$scrollTop();
        this.officerOptions = [];
      }
    },
    setTimePickerModes(planDate, fromDate, toDate) {
      if ( moment(planDate).isSame(fromDate, "day") && moment(planDate).isSame(toDate, "day")) {
        this.shiftStartPickerMode = "time";
        this.shiftEndPickerMode = "time";
      } else if (moment(toDate).isAfter(planDate, "day")) {
        this.shiftStartPickerMode = "datetime";
        this.shiftEndPickerMode = "datetime";
      }
    },

    // 计算实际工作时间段
    calculateSegments(time1Str, time2Str, hours) {
      // console.log('实际应该工作时长--',hours);
      const startDate = new Date(time1Str);
      const endDate = new Date(time2Str);
      const startStr = startDate.getTime();
      const endStr = endDate.getTime();
      const totalTimeDiff = endStr - startStr;

      // 将 "hours" 转换为毫秒数
      const [actualHours, actualMinutes, actualSeconds] = hours.split(':').map(Number);
      const actualTimeMilliseconds = actualHours * 3600000 + actualMinutes * 60000 + actualSeconds * 1000;
      
      // 确保总时间差大于实际时间
      if (totalTimeDiff <= actualTimeMilliseconds) {
        console.log("结束时间与开始时间与实际时间差值不能小于0");
        return;
      }
      // 工作一半的时间(毫秒)
      const halfNum = actualTimeMilliseconds / 2;
      const aEndMilliseconds = startStr + halfNum;
      const bStartMilliseconds = endStr - halfNum;

      const aStartTimeString = this.getTimeString(startStr);
      const aEndTimeString = this.getTimeString(aEndMilliseconds);
      const bStartTimeString = this.getTimeString(bStartMilliseconds);
      const bEndTimeString = this.getTimeString(endStr);
      return {
        startTimg:{
          from: aStartTimeString,
          to: aEndTimeString
        },
        endTimg:{
          from: bStartTimeString,
          to: bEndTimeString
        },
      };
    },
    getTimeString(ms) {
      var date = new Date(ms);
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0');
      var day = String(date.getDate()).padStart(2, '0');
      var hours = String(date.getHours()).padStart(2, '0');
      var minutes = String(date.getMinutes()).padStart(2, '0');
      var seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style scope>
.staff_unavail .AsomCard__Body {
  @apply sm:rounded-tr-md sm:rounded-tl-md;
}
</style>
